import Vue from 'vue'
import VueToast from 'vue-toast-notification'
import App from './App.vue'
import router from './router'
import store from './store'
import vSelect from 'vue-select'
import 'vue-toast-notification/dist/theme-bootstrap.css'
import './assets/css/main.css'
import 'vue-select/dist/vue-select.css';
import vueDebounce from 'vue-debounce'

Vue.use(vueDebounce);
Vue.use(VueToast);

Vue.component('v-select', vSelect)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app2')
