<template>
  <div class="page">
    <Exams />
  </div>
</template>

<script>
  import Exams from '@/components/Exams'
  export default {
    name: "Main",
    components: {
      Exams,
    }
  }
</script>