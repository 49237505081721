import axios from 'axios'
import settings from '@/settings.js'
let token = localStorage.getItem('token');

export default {
  namespaced: true,
  state: {
    list: [],
    item: null,
    meta: {},
    filters: {
      page: 1,
      query: ''
    }
  },

  getters: {
    list: state => {
      return state.list;
    },
    item: state => {
      return state.item;
    },
    meta: state => {
      return state.meta;
    },
  },

  actions: {
    async getList({commit, state}) {
      const params = Object.fromEntries(Object.entries(state.filters).filter(([key, value]) => key && value));
      let list = await axios.get(`${settings.APP_SERVICE_URL}rest/client`, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params
      });
      commit('SET_LIST', list.data)
    },
    async getClient({commit}, id) {
      let item = await axios.get(`${settings.APP_SERVICE_URL}rest/client-exam/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      commit('SET_ITEM', item.data)
    },
    /* eslint-disable no-empty-pattern */
    async save({ }, payload) {
      await axios.post(`${settings.APP_SERVICE_URL}rest/client-exam/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
    },
    async update({ commit }, payload) {
      let client = await axios.patch(`${settings.APP_SERVICE_URL}rest/client-exam/${payload.id}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      commit('exams/SET_EXAM_BY_DATA', client.data, { root: true })
    },
    async del({ }, payload) {
      await axios.delete(`${settings.APP_SERVICE_URL}rest/client-exam/${payload.id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
    },
    resetPage({ commit }) {
      commit('RESET_PAGE');
    },
    changeQueryClients({ commit }, query) {
      commit('SET_QUERY_CLIENTS',query);
    },
    clear({ commit }) {
      commit('CLEAR');
    }
  },

  mutations: {
    SET_LIST(state, list) {
      state.filters.page++;
      state.list.push(...list.data);
      state.meta  = list.meta;
    },
    SET_ITEM(state, item) {
      state.item  = item.client_exam;
    },
    RESET_PAGE(state) {
      state.filters.page = 1;
    },
    SET_QUERY_CLIENTS(state, query) {
      state.filters.query = query;
    },
    CLEAR(state) {
      state.list = [];
      state.meta = {};
      state.filters.page = 1;
    },
  },
};
