<template>
	<div class="page">
		<div class="settings">
			<div class="settings-top">
				<div class="settings-head settings-head--exams d-flex align-items-center flex-wrap">
					<router-link to="/" class="account-back d-flex align-items-center mr-4">
						<i class="fas fa-chevron-left"></i>
						<div class="account-back-text">Назад</div>
					</router-link>
					<div class="settings-title">Настройки</div>
				</div>
			</div>
		</div>
		<div class="school-cabinet"> 
			<div class="school-cabinet_card">
				<div class="school-cabinet_card-info d-flex align-items-center">
					<div class="school-cabinet_card-info-item mr-4">
						<div class="school-cabinet_card-info-title">
							<strong>Локации</strong>
						</div>
						<div class="school-cabinet_card-info-text mb-2 mb-md-0">
							<span class="light" v-for="loc in locations" :key="loc.id">{{ loc.mreo }}, </span>
						</div>
					</div>
					<router-link to="/locations" class="school-cabinet_card-edit ml-auto"></router-link>
				</div>
			</div>
			<div class="school-cabinet_card">
				<div class="school-cabinet_card-info d-flex align-items-center">
					<div class="school-cabinet_card-info-item mr-4">
						<div class="school-cabinet_card-info-title">
							<strong>Водительские категории</strong>
						</div>
						<div class="school-cabinet_card-info-text mb-2 mb-md-0">
							<span class="light" v-for="category in categories" :key="category.id">{{ category.category }}, </span>
						</div>
					</div>
					<router-link to="/categories" class="school-cabinet_card-edit ml-auto"></router-link>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
	name: "Settings",
	mounted() {
		this.getLocations(this.page);
		this.getCategories(this.page);
	},
	destroyed() {
		this.clearLocations();
		this.clearCategories();
	},
	computed: {
		...mapGetters('locations', {
			locations: 'list',
			meta: 'meta',
		}),
		...mapGetters('categories', {
			categories: 'list',
			meta: 'meta',
		}),
	},
	methods: {
		...mapActions('locations', {
			getLocations: 'getList',
			clearLocations: 'clear',
		}),
		...mapActions('categories', {
			getCategories: 'getList',
			clearCategories: 'clearCategories',
		}),
	}
}
</script>