<template>
  <div id="app2" class="wrapper__1">
    <div class="wrapper__1__content" v-if="token">
      <router-view />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: 'App',
  data() {
    return {
      token: null,
    }
  },
  mounted() {
    if (this.$route.query.token) {
      this.token = this.$route.query.token;
      this.getToken(this.$route.query.token);
    } else {
      if (localStorage.getItem('token')) {
        this.token = localStorage.getItem('token');
      }
    }
  },
  methods: {
    ...mapActions('auth', {
      getToken: 'getToken',
    }),
  }
}
</script>