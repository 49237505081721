import Vue from 'vue';
import Vuex from 'vuex';

import auth from './modules/auth';
import exams from './modules/exams';
import categories from './modules/categories';
import locations from './modules/locations';
import statuses from './modules/statuses';
import instructors from './modules/instructors';
import clients from './modules/clients';
import statusesClient from './modules/statusesClient';
import promocodes from './modules/promocodes';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    exams,
    categories,
    locations,
    statuses,
    instructors,
    clients,
    statusesClient,
    promocodes,
  },
});
