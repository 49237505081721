<template>
	<div class="page">
		<div class="settings">
			<div class="settings-top">
				<div class="settings-head settings-head--exams d-flex align-items-center flex-wrap">
					<router-link to="/locations" class="account-back d-flex align-items-center mr-4">
						<i class="fas fa-chevron-left"></i>
						<div class="account-back-text">Назад</div>
					</router-link>
					<div class="settings-title">Локации</div>
				</div>
			</div>
			<div class="settings-title settings-title--small">Редактировать локацию</div>
		</div>
		<div class="school-cabinet">
			<div class="school-cabinet_white-box school-cabinet_white-box--bigger mb-4" v-if="loc">
				<div class="school-cabinet_info-form school-cabinet_info-form--full">
					<div class="d-flex justify-content-between flex-wrap">
						<div class="school-cabinet_info-field school-cabinet_info-field--small mb-md-0">
							<input type="text" class="form-control form-control--bordered" placeholder="МРЭО" v-model="location.mreo">
						</div>
						<div class="school-cabinet_info-field school-cabinet_info-field--small mb-md-0">
							<input type="text" class="form-control form-control--bordered" placeholder="Адрес" v-model="location.address">
						</div>
						<div class="school-cabinet_info-field school-cabinet_info-field--small mb-0">
							<button class="btn btn-blue btn-full" @click="update" v-if="!isLoading">Сохранить</button>
							<button class="btn btn-blue btn-full" disabled v-else>Сохраняем...</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import errorHandlerForResponse from '@/helpers/errorHandlerForResponse.js';

export default {
	name: "LocationEdit",
	data() {
		return {
			isLoading: false,
			location: {
				mreo: '',
				address: '',
			}
		}
	},
	mounted() {
		let id = this.$route.params.id;
		this.getLocation(id).then(() => {
			this.location.mreo = this.loc.mreo;
			this.location.address = this.loc.address;
		});
	},
	destroyed() {
		this.clearLocations();
	},
	computed: {
		...mapGetters('locations', {
			loc: 'item',
		}),
	},
	methods: {
		...mapActions('locations', {
			getLocation: 'getLocation',
			clearLocations: 'clear',
			editLocation: 'update',
		}),
		update() {
			this.isLoading = true;
			let id = this.loc.location_id;
			let payload = {
				"id": id,
				"type": "PATCH",
				"location": this.location
			}
			this.editLocation(payload).then(() => {
				this.isLoading = false;
				this.$router.push('/locations');
			}).catch((e) => {
				this.isLoading = false;
				this.errorHandlerForResponse(e);
			})
		},
		errorHandlerForResponse,
	}
}
</script>