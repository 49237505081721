import axios from 'axios'
import settings from '@/settings.js'
let token = localStorage.getItem('token');

export default {
  namespaced: true,
  state: {
    list: [],
    meta: {},
    page: 1,
  },

  getters: {
    list: state => {
      return state.list;
    },
    meta: state => {
      return state.meta;
    },
  },

  actions: {
    async getList({commit, state}, page) {
      if (page) {
        let list = await axios.get(`${settings.APP_SERVICE_URL}rest/examination/status?page=${page}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        commit('SET_LIST', list.data)
      } else {
        let list = await axios.get(`${settings.APP_SERVICE_URL}rest/examination/status?page=${state.page}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        commit('PUSH_LIST', list.data)
      }
    },
    clear({ commit }) {
      commit('CLEAR');
    }
  },

  mutations: {
    PUSH_LIST(state, list) {
      state.page++;
      state.list.push(...list.data);
      state.meta  = list.meta;
    },
    SET_LIST(state, list) {
      state.list  = list.data;
      state.meta  = list.meta;
    },
    CLEAR(state) {
      state.list = [];
      state.meta = {};
      state.page = 1;
    },
  },
};
