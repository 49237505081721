import Vue from 'vue'
import VueRouter from 'vue-router'

import Main from '../views'
import ExamsAdd from '../views/exams-add'
import ExamEdit from '../views/exam-edit'
import Locations from '../views/locations'
import LocationEdit from '../views/location-edit'
import Settings from '../views/settings'
import Categories from '../views/categories'
import CategoryEdit from '../views/category-edit'
import Clients from '../views/clients'
import ClientsEdit from '../views/clientsEdit'
import Promocodes from '../views/promocodes'
import PromocodeAdd from '../views/promocode-add'
import PromocodeEdit from '../views/promocode-edit'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Main,
    meta: {
      title: 'Экзамены',
    },
  },
  {
    path: '/exams-add',
    component: ExamsAdd,
    meta: {
      title: 'Добавить экзамен',
    },
  },
  {
    path: '/exam-edit/:id',
    component: ExamEdit,
    meta: {
      title: 'Редактировать экзамен',
    },
  },
  {
    path: '/locations',
    component: Locations,
    meta: {
      title: 'Локации',
    },
  },
  {
    path: '/location-edit/:id',
    component: LocationEdit,
    meta: {
      title: 'Редактировать локацию',
    },
  },
  {
    path: '/settings',
    component: Settings,
    meta: {
      title: 'Настройки',
    },
  },
  {
    path: '/categories',
    component: Categories,
    meta: {
      title: 'Водительские категории',
    },
  },
  {
    path: '/category-edit/:id',
    component: CategoryEdit,
    meta: {
      title: 'Редактировать водительскую категорию',
    },
  },
  {
    path: '/clients/:id',
    component: Clients,
    meta: {
      title: 'Клиенты',
    },
  },
  {
    path: '/clients/:id/edit',
    component: ClientsEdit,
    meta: {
      title: 'Редактировать клиента',
    },
  },
  {
    path: '/promocodes',
    component: Promocodes,
    meta: {
      title: 'Промокоды',
    },
  },
  {
    path: '/promocode-add',
    component: PromocodeAdd,
    meta: {
      title: 'Добавить промокод',
    },
  },
  {
    path: '/promocode-edit/:id',
    component: PromocodeEdit,
    meta: {
      title: 'Редактировать промокод',
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  linkActiveClass: 'active',
  routes
})

export default router
