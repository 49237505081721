<template>
	<div>
		<div class="settings">
			<div class="settings-top">
				<div class="settings-head settings-head--exams d-flex align-items-center flex-wrap">
					<router-link to="/promocodes" class="account-back d-flex align-items-center mr-4">
						<i class="fas fa-chevron-left"></i>
						<div class="account-back-text">Назад</div>
					</router-link>
				</div>
			</div>
			<div class="settings-title settings-title--small">Добавить промокод</div>
		</div>
		<div class="school-cabinet">
			<div class="school-cabinet_white-box school-cabinet_white-box--bigger mb-4">
				<div class="school-cabinet_info-form school-cabinet_info-form--full">
					<div class="d-flex justify-content-between flex-wrap">
						<div class="school-cabinet_info-field school-cabinet_info-field--small mb-md-0">
							<input type="text" class="form-control form-control--bordered" placeholder="Промокод" v-model="promocode.code">
						</div>
						<div class="school-cabinet_info-field school-cabinet_info-field--calendar school-cabinet_info-field--small mb-md-0">
							<input readonly type="text" class="form-control form-control--calendar-icon form-control--bordered" placeholder="Срок действия" id="calendar-modal">
						</div>
						<div class="school-cabinet_info-field school-cabinet_info-field--small mb-0">
							<button class="btn btn-blue btn-full" @click="save" v-if="!isLoading">Сохранить</button>
							<button class="btn btn-blue btn-full" disabled v-else>Сохраняем...</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions } from 'vuex';
import AirDatepicker from 'air-datepicker';
import 'air-datepicker/air-datepicker.css';
import moment from 'moment';
import errorHandlerForResponse from '@/helpers/errorHandlerForResponse.js';

export default {
	name: "PromocodeAdd",
	data() {
		return {
			isLoading: false,
			promocode: {
				code: '',
				active_from: '',
				active_to: '',
			}
		}
	},
	mounted() {
		let that = this;
		new AirDatepicker('#calendar-modal', {
			range: true,
			multipleDatesSeparator: ' - ',
			onSelect(date) {
				if (date.date.length > 1) {
					that.promocode.active_from = moment(date.date[0]).format('DD.MM.YYYY')
					console.log(date.date[0]);
					that.promocode.active_to = moment(date.date[1]).format('DD.MM.YYYY')
					console.log(date.date[1]);
				}
			}
		});
	},
	destroyed() {
	},
	methods: {
		...mapActions('promocodes', {
			setPromocode: 'save',
		}),
		save() {
			this.isLoading = true;
			let payload = this.promocode
			this.setPromocode(payload).then(() => {
				this.isLoading = false;
				this.$router.push('/promocodes');
			}).catch((e) => {
				this.isLoading = false;
				this.errorHandlerForResponse(e);
			})
		},
		errorHandlerForResponse,
	}
}
</script>