<template>
	<div class="page">
		<div class="settings">
			<div class="settings-top">
				<div class="settings-head settings-head--exams d-flex align-items-center flex-wrap">
					<router-link to="/" class="account-back d-flex align-items-center mr-4">
						<i class="fas fa-chevron-left"></i>
						<div class="account-back-text">Назад</div>
					</router-link>
					<div class="settings-title mr-auto" v-if="exam">Экзамен {{exam.driver_category.name}}, {{formatDate(exam.date)}}</div>
					<div class="settings-search settings-search--exams d-none d-md-block">
						<input 
							type="text" 
							class="form-control form-control--search" 
							placeholder="Поиск по клиентам" 
							v-debounce:300ms="searchFire"
						>
						<button></button>
					</div>
				</div>
			</div>
			<div class="settings-title settings-title--small">Добавить клиента</div>
			<div class="school-cabinet_info-form school-cabinet_info-form--white-box school-cabinet_info-form--full">
				<div class="school-cabinet_info-row">
					<div class="d-flex justify-content-between flex-wrap">
						<div class="school-cabinet_info-field school-cabinet_info-field--little mb-3">
							<v-select 
								:options="clients" 
								:reduce="client => client.client_id" 
								:filterable="false"
								:searchable="true"
								label="full_name"
								placeholder="Клиенты"
								v-model="client_exam.client_id"
								@open="onOpenClients"
								@close="onCloseClients"
								@search="searchClient"
								>
								<template #list-footer>
									<li v-show="hasNextPage" ref="load" class="loader select-loader">
										Загрузка...
									</li>
								</template>
							</v-select>
						</div>
						<div class="school-cabinet_info-field school-cabinet_info-field--little mb-3">
							<v-select 
								:options="instructors" 
								:filterable="false"
								:reduce="instructor => instructor.instructor_id" 
								label="full_name" 
								:searchable="true" 
								placeholder="Инструкторы"
								v-model="client_exam.instructor_id"
								@open="onOpenInstructors"
								@close="onCloseInstructors"
								@search="searchInstructor"
								>
								<template #list-footer>
									<li v-show="hasNextPageInstructors" ref="loadInstructors" class="loader select-loader">
										Загрузка...
									</li>
								</template>
							</v-select>
						</div>
						<div class="school-cabinet_info-field school-cabinet_info-field--little mb-3">
							<v-select 
								:options="statusesClient" 
								:reduce="status => status.code" 
								label="name" 
								:searchable="false" 
								placeholder="Статус"
								v-model="client_exam.status"
								>
							</v-select>
						</div>
						<div class="school-cabinet_info-field school-cabinet_info-field--little mb-3">
							<input type="text" class="form-control form-control--bordered" placeholder="Кол-во неучтённых/проваленных экзаменов" v-model="client_exam.previous_failed_attempts">
						</div>
						<div class="school-cabinet_info-field school-cabinet_info-field--little m-auto">
							<button class="btn btn-blue btn-full" @click="save" v-if="!isLoading">Сохранить</button>
							<button class="btn btn-blue btn-full" disabled v-else>Сохраняем...</button>
						</div>
					</div>
				</div>
			</div>
			<div class="filters d-flex flex-wrap filters--exams align-items-center">
				<!-- <div class="filters-item mb-2 mb-md-0">
					<v-select 
						:options="clients" 
						:reduce="client => client.client_id" 
						:filterable="false"
						:searchable="true"
						label="full_name"
						placeholder="Клиент"
						@open="onOpenClients"
						@close="onCloseClients"
						@search="searchClient"
						@input="selectClient"
						>
						<template #list-footer>
							<li v-show="hasNextPage" ref="load" class="loader select-loader">
								Загрузка...
							</li>
						</template>
					</v-select>
				</div>
				<div class="filters-item mb-2 mb-md-0">
					<v-select 
						:options="instructors" 
						:filterable="false"
						:reduce="instructor => instructor.instructor_id" 
						label="full_name" 
						:searchable="false" 
						placeholder="Инструктор"
						@open="onOpenInstructors"
						@close="onCloseInstructors"
						@input="selectInstructor"
						>
						<template #list-footer>
							<li v-show="hasNextPageInstructors" ref="loadInstructors" class="loader select-loader">
								Загрузка...
							</li>
						</template>
					</v-select>
				</div> -->
				<div class="filters-item">
					<v-select 
						:options="statuses" 
						:reduce="status => status.code" 
						label="name" 
						:searchable="false" 
						placeholder="Статус"
						@input="selectStatus"
						ref="statusSelect"
						>
					</v-select>
				</div>
				<button type="button" class="reset-filters filters-item--100 align-self-center mt-3 mb-3 mt-xl-0 mb-md-0 mr-auto" @click="resetFilters"> Сбросить фильтры </button>
				<div class="settings-btn settings-btn--grey ml-auto">
					<a href="javascript:void(0)" @click="exportAction"> Экспорт данных <span class="icon icon--export"></span></a>
				</div>
			</div>
			<div class="school-cabinet_wrapper">
				<div class="school-cabinet_wrapper-content" data-simplebar data-simplebar-auto-hide="false" v-if="exams && exams.length > 0">
					<div class="school-cabinet_table school-cabinet_table--exam-clients">
						<div class="school-cabinet_table-title school-cabinet_table-title--1">Клиент</div>
						<div class="school-cabinet_table-title school-cabinet_table-title--2">Инструктор</div>
						<div class="school-cabinet_table-title school-cabinet_table-title--3">Статус</div>
						<div class="school-cabinet_table-title school-cabinet_table-title--4">Доп. запись</div>
					</div>
					<div class="school-cabinet_card school-cabinet_card--exam-clients" v-for="client in exams" :key="client.client_exam_id">
						<div class="school-cabinet_card-top d-flex align-items-center">
							<div class="school-cabinet_card-info school-cabinet_card-info--id">
								<div class="school-cabinet_card-info-item">
									<div class="school-cabinet_card-info-text">
										<span class="light">{{client.number}}</span>
									</div>
								</div>
							</div>
							<a href="javascript:void(0)" class="school-cabinet_card-content school-cabinet_card-content--client d-flex align-items-center">
								<div class="school-cabinet_card-icon">
									<img :src="client.client.avatar.url" v-if="client.client.avatar.url" alt="">
									<img src="../assets/img/no-avatar.png" alt="" v-else>
								</div>
								<div class="school-cabinet_card-text">
									<div class="school-cabinet_card-name">{{client.client.full_name}}</div>
									<div class="school-cabinet_card-facts">{{client.client.email}}, {{client.client.phone}}</div>
								</div>
							</a>
							<div class="school-cabinet_card-info school-cabinet_card-info--instructor">
								<div class="school-cabinet_card-info-item mr-0 pr-0 pr-md-1">
									<div class="school-cabinet_card-info-title" v-if="client && client.instructor && client.instructor.full_name">
										<span class="normal">{{client.instructor.full_name}}</span>
									</div>
									<div class="school-cabinet_card-info-title" v-else>
										<span class="normal">—</span>
									</div>
								</div>
							</div>
							<div class="school-cabinet_card-info school-cabinet_card-info--status">
								<div class="school-cabinet_card-info-item mr-0 pr-0 pr-md-1">
									<div class="school-cabinet_card-info-title">
										<span class="normal">{{ getClientStatus(client.status) }}</span>
									</div>
								</div>
							</div>
							<div class="school-cabinet_card-toggle mt-1 mt-md-0" v-if="client.additional_registration_allowed == 1" @click="changeToggle(client)">
								<label class="toggle-box toggle-box--white" :for="`add_registration_${client.client_exam_id}`" :class="{loading: loadingIds.includes(client.client_exam_id)}">
									<input disabled checked type="checkbox" class="toggle" :id="`add_registration_${client.client_exam_id}`">
									<div class="toggle-handler"></div>
								</label>
							</div>
							<div class="school-cabinet_card-toggle mt-1 mt-md-0" v-else @click="changeToggle(client)" :class="{loading: loadingIds.includes(client.client_exam_id)}">
								<label class="toggle-box toggle-box--white" :for="`add_registration_${client.client_exam_id}`">
									<input disabled type="checkbox" class="toggle" :id="`add_registration_${client.client_exam_id}`" >
									<div class="toggle-handler"></div>
								</label>
							</div>
							<div class="d-flex align-items-center ml-auto">
								<router-link :to="`/clients/${client.client_exam_id}/edit/?exam=${exam.examination_id}`" class="school-cabinet_card-edit ml-4"></router-link>
								<div class="school-cabinet_card-delete school-cabinet_card-delete--2 ml-4" @click="deleteConfirmation(client.client_exam_id)"></div>
							</div>
						</div>
					</div>
				</div>
				<p v-else>Клиентов пока нет</p>
			</div>
		</div>
		<v-dialog />
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import errorHandlerForResponse from '@/helpers/errorHandlerForResponse.js';
import moment from 'moment';
import _ from 'lodash';

import Vue from 'vue'
import VModal from 'vue-js-modal';
Vue.use(VModal, { dialog: true });

export default {
	name: "Clients",
	data() {
		return {
			page: 1,
			client: null,
			observer: null,
			observerInstructors: null,
			isLoading: false,
			loadingIds: [],
			client_exam: {
				client_id: '',
				instructor_id: '',
				examination_id: '',
				status: '',
				additional_registration_allowed: 0,
				previous_failed_attempts: null,
			},
			search: '',
		}
	},
	mounted() {
		let id = this.$route.params.id;
		this.client_exam.examination_id = id;
		this.getExam(id);
		this.getStatusesClient();
		this.getExamInstructors(id);
		this.getClients();
		this.getExamClients(id);
		this.observer = new IntersectionObserver(this.infiniteScroll);
		this.observerInstructors = new IntersectionObserver(this.infiniteScrollInstructors);
	},
	destroyed() {
		this.clearClients();
		this.clearInstructors();
		this.clearStatusesClient();
		this.clearExam();
	},
	computed: {
		...mapGetters('exams', {
			exam: 'item',
			exams: 'clients',
		}),
		...mapGetters('statusesClient', {
			statusesClient: 'list',
			statuses: 'statuses',
		}),
		...mapGetters('clients', {
			clients: 'list',
			clientsMeta: 'meta',
		}),
		...mapGetters('instructors', {
			instructors: 'instructors',
			instructorsMeta: 'instructors_meta',
		}),
		hasNextPage() {
			return this.clients.length < parseInt(this.clientsMeta.total)
		},
		hasNextPageInstructors() {
			return this.instructors.length < parseInt(this.instructorsMeta.total)
		},
	},
	methods: {
		...mapActions('exams', {
			getExam: 'getExam',
			clearExam: 'clearExam',
			getExamClients: 'getExamClients',
			clearExamClients: 'clearExamClients',
			resetExamClientsFilters: 'resetFilters',
			setParam: 'setParamClients',
			exportData: 'exportData',
			updateClientStatus: 'updateClientStatus',
		}),
		...mapActions('statusesClient', {
			getStatusesClient: 'getList',
			clearStatusesClient: 'clear',
		}),
		...mapActions('clients', {
			getClients: 'getList',
			resetPage: 'resetPage',
			clearClients: 'clear',
			setClient: 'save',
			delClient: 'del',
			editClient: 'update',
			changeQueryClients: 'changeQueryClients',
		}),
		...mapActions('instructors', {
			getExamInstructors: 'getExamInstructors',
			clearInstructors: 'clear',
			changeQueryInstructors: 'changeQueryInstructors',
		}),
		getClientStatus(code) {
			if (code == 'assignment') {
				return 'Экзамен назначен'
			}
			if (code == 'examination') {
				return 'Назначен экзамен'
			}
			if (code == 'confirmed') {
				return 'Подтвержден'
			}
			if (code == 'success') {
				return 'Успешно'
			}
			if (code == 'fail') {
				return 'Провален'
			}
			if (code == 'canceled') {
				return 'Отменен'
			}
			if (code == 'not_confirmed') {
				return 'Не подтвержден'
			}
			if (code == 'abandoned') {
				return 'Забирает документы'
			}
		},
		// selectClient(client) {
		// 	let payload = {
		// 		type: 'client_id',
		// 		value: client,
		// 	} 
		// 	this.setParam(payload).then(() => {
		// 		let id = this.$route.params.id;
		// 		this.client_exam.examination_id = id;
		// 		this.clearExamClients().then(() => {
		// 			this.getExamClients(id);
		// 		})
		// 	})
		// },
		// selectInstructor(instructor) {
		// 	let payload = {
		// 		type: 'instructor_id',
		// 		value: instructor,
		// 	} 
		// 	this.setParam(payload).then(() => {
		// 		let id = this.$route.params.id;
		// 		this.client_exam.examination_id = id;
		// 		this.clearExamClients().then(() => {
		// 			this.getExamClients(id);
		// 		})
		// 	})
		// },
		selectStatus(status) {
			let payload = {
				type: 'status',
				value: status,
			} 
			this.setParam(payload).then(() => {
				let id = this.$route.params.id;
				this.client_exam.examination_id = id;
				this.clearExamClients().then(() => {
					this.getExamClients(id);
				})
			})
		},
		resetFilters() {
			this.$refs.statusSelect.clearSelection();
			//this.search = '';
			this.resetExamClientsFilters().then(() => {
				let id = this.$route.params.id;
				this.client_exam.examination_id = id;
				this.clearExamClients().then(() => {
					this.getExamClients(id);
				})
			})
		},
		searchFire(val) {
			let payload = {
				type: 'search',
				value: val,
			} 
			this.setParam(payload).then(() => {
				let id = this.$route.params.id;
				this.client_exam.examination_id = id;
				this.getExamClients(id);
			})
		},
		searchClient(search, loading) {
			this.clearClients();
			this.changeQueryClients(search);
			loading(true);
			this.searchClientsAction(search, loading, this);
		},
		searchClientsAction: _.debounce((search, loading, vm) => {
			vm.getClients(false).then(() => {
				loading(false);
			})
		}, 1000),
		searchInstructor(search, loading) {
			this.clearInstructors();
			this.changeQueryInstructors(search);
			loading(true);
			this.searchInstructorsAction(search, loading, this);
		},
		searchInstructorsAction: _.debounce((search, loading, vm) => {
			vm.getExamInstructors(vm.$route.params.id).then(() => {
				loading(false);
			})
		}, 1000),
		formatDate(date) {
			return moment(date).format('DD.MM.YYYY');
		},
		deleteConfirmation(id) {
			this.$modal.show('dialog', {
				text: 'Вы действительно хотите удалить клиента?',
				buttons: [
				{
					title: 'Да',
					handler: () => {
					this.del(id);
					this.$modal.hide('dialog');
					},
				},
				{
					title: 'Нет',
					handler: () => {
					this.$modal.hide('dialog');
					},
				},
				],
			});
		},
		del(id) {
			let payload = {
				"id": id,
			}
			this.delClient(payload).then(() => {
				this.resetPage();
				let id = this.$route.params.id;
				this.getExamClients(id);
			})
		},
		exportAction() {
			this.exportData(this.$route.params.id);
		},
		save() {
			this.isLoading = true;
			let payload = {
				"client_exam": this.client_exam
			}
			this.setClient(payload).then(() => {
				this.isLoading = false;
				this.resetPage();
				let id = this.$route.params.id;
				this.getExamClients(id);
				this.client_exam = {
					client_id: '',
					instructor_id: '',
					examination_id: this.$route.params.id,
					status: '',
				}
			}).catch((e) => {
				this.isLoading = false;
				this.errorHandlerForResponse(e);
			})
		},
		changeToggle(client) {
			let id = client.client_exam_id;
			if (this.loadingIds.includes(id)) {
				return false;
			}
			this.loadingIds.push(id);
			let payload = {
				"id": id,
				"type": "PATCH",
				client_exam: {
					additional_registration_allowed: !client.additional_registration_allowed,
				}
			}
			this.updateClientStatus(client);
			this.editClient(payload).then(() => {
				let index = this.loadingIds.indexOf(id);
				if (index > -1) {
					this.loadingIds.splice(index, 1);
				}
			})
		},
		async onOpenClients() {
			if (this.hasNextPage) {
				await this.$nextTick()
				this.observer.observe(this.$refs.load)
			}
		},
		onCloseClients() {
			this.observer.disconnect()
		},
		async onOpenInstructors() {
			if (this.hasNextPageInstructors) {
				await this.$nextTick()
				this.observerInstructors.observe(this.$refs.loadInstructors)
			}
		},
		onCloseInstructors() {
			this.observerInstructors.disconnect()
		},
		async infiniteScroll([{ isIntersecting, target }]) {
			if (isIntersecting) {
				const ul = target.offsetParent
				const scrollTop = target.offsetParent.scrollTop
				await this.getClients();
				ul.scrollTop = scrollTop
			}
		},
		async infiniteScrollInstructors([{ isIntersecting, target }]) {
			if (isIntersecting) {
				const ul = target.offsetParent
				const scrollTop = target.offsetParent.scrollTop
				await this.getInstructors();
				ul.scrollTop = scrollTop
			}
		},
		errorHandlerForResponse,
	}
}
</script>

<style>
	.select-loader {
		padding: 20px;
	}
	@media screen and (max-width: 1000px) {
		.school-cabinet_info-form--full .school-cabinet_info-field--little {
			width: 49% !important;
		}
	}
	@media screen and (max-width: 767px) {
		.school-cabinet_info-form--full .school-cabinet_info-field--little {
			width: 100% !important;
		}
	}
	.toggle-box.loading:after {
		content: '';
		display: block;
		width: 100%;
		height: 100%;
		background-color: rgba(255,255,255,0.6);
		position: absolute;
		z-index: 100;
	}
</style>