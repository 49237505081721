<template>
	<div class="page">
		<div class="settings">
			<div class="settings-top">
				<div class="settings-head settings-head--exams d-flex align-items-center flex-wrap">
					<router-link to="/settings" class="account-back d-flex align-items-center mr-4">
						<i class="fas fa-chevron-left"></i>
						<div class="account-back-text">Назад</div>
					</router-link>
					<div class="settings-title">Локации</div>
				</div>
			</div>
			<div class="settings-title settings-title--small">Добавить локацию</div>
		</div> 
		<div class="school-cabinet">
			<div class="school-cabinet_white-box school-cabinet_white-box--bigger mb-4">
				<div class="school-cabinet_info-form school-cabinet_info-form--full">
					<div class="d-flex justify-content-between flex-wrap">
						<div class="school-cabinet_info-field school-cabinet_info-field--small mb-md-0">
							<input type="text" class="form-control form-control--bordered" placeholder="МРЭО" v-model="location.mreo">
						</div>
						<div class="school-cabinet_info-field school-cabinet_info-field--small mb-md-0">
							<input type="text" class="form-control form-control--bordered" placeholder="Адрес" v-model="location.address">
						</div>
						<div class="school-cabinet_info-field school-cabinet_info-field--small mb-0">
							<button class="btn btn-blue btn-full" @click="save" v-if="!isLoading">Добавить</button>
							<button class="btn btn-blue btn-full" disabled v-else>Добавляем...</button>
						</div>
					</div>
				</div>
			</div>
			<div class="school-cabinet_wrapper">
				<div class="school-cabinet_wrapper-content" data-simplebar data-simplebar-auto-hide="false">
					<div class="school-cabinet_table school-cabinet_table--exam-settings d-none d-md-flex">
						<div class="school-cabinet_table-title mr-1">МРЭО</div>
						<div class="school-cabinet_table-title mr-1">Адрес</div>
						<div class="school-cabinet_table-title school-cabinet_table-title--empty"></div>
					</div>
					<div class="school-cabinet_card school-cabinet_card--exam-settings" v-for="loc in locations" :key="loc.id">
						<div class="school-cabinet_card-info d-flex align-items-center">
							<div class="school-cabinet_card-info-item mr-1">
								<div class="school-cabinet_info-table-title d-md-none">МРЭО</div>
								<div class="school-cabinet_card-info-text mb-2 mb-md-0">
									<span class="normal">{{ loc.mreo }}</span>
								</div>
							</div>
							<div class="school-cabinet_card-info-item mr-1">
								<div class="school-cabinet_info-table-title d-md-none">Адрес</div>
								<div class="school-cabinet_card-info-text mb-2 mb-md-0">
									<span class="normal">{{ loc.address }}</span>
								</div>
							</div>
							<div class="d-flex align-items-center ml-auto">
								<router-link :to="`/location-edit/${loc.location_id}`" class="school-cabinet_card-edit ml-4"></router-link>
								<div class="school-cabinet_card-delete school-cabinet_card-delete--2 ml-4" @click="deleteConfirmation(loc.location_id)"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="custom-paginate">
			<pagination v-model="page" v-if="meta.total" :per-page="meta.per_page" :records="meta.total" @paginate="cahngeLocationsPage" :options="options" />
		</div>
		<v-dialog />
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import errorHandlerForResponse from '@/helpers/errorHandlerForResponse.js';

import Pagination from 'vue-pagination-2';

import Vue from 'vue'
import VModal from 'vue-js-modal';
Vue.use(VModal, { dialog: true });

export default {
	name: "Locations",
	data() {
		return {
			page: 1,
			isLoading: false,
			location: {
				mreo: '',
				address: '',
			},
			options: {
				edgeNavigation: false,
				texts: {
					count: ''
				}
			},
		}
	},
	components: {
		Pagination,
	},
	mounted() {
		this.getLocations(this.page);
	},
	destroyed() {
		this.clearLocations();
	},
	computed: {
		...mapGetters('locations', {
			locations: 'list',
			meta: 'meta',
		}),
	},
	methods: {
		...mapActions('locations', {
			getLocations: 'getList',
			clearLocations: 'clear',
			setLocation: 'save',
			delLocation: 'del',
		}),
		cahngeLocationsPage() {
			this.getLocations(this.page);
		},
		deleteConfirmation(id) {
		this.$modal.show('dialog', {
			text: 'Вы действительно хотите удалить локацию?',
			buttons: [
			{
				title: 'Да',
				handler: () => {
				this.del(id);
				this.$modal.hide('dialog');
				},
			},
			{
				title: 'Нет',
				handler: () => {
				this.$modal.hide('dialog');
				},
			},
			],
		});
		},
		del(id) {
			let payload = {
				"id": id,
			}
			this.delLocation(payload).then(() => {
				this.clearLocations();
				this.getLocations(this.page);
			})
		},
		save() {
			this.isLoading = true;
			let payload = {
				"location": this.location
			}
			this.setLocation(payload).then(() => {
				this.clearLocations();
				this.isLoading = false;
				this.getLocations(this.page);
				this.location = {
					mreo: '',
					address: '',
				}
			}).catch((e) => {
				this.isLoading = false;
				this.errorHandlerForResponse(e);
			})
		},
		errorHandlerForResponse,
	}
}
</script>