<template>
	<div>
		<div class="settings">
			<div class="settings-top">
				<div class="settings-head d-flex align-items-center flex-wrap">
					<div class="settings-title">Экзамены</div>
					<router-link to="/exams-add" class="settings-head-icon"></router-link>
					<div class="settings-btns ml-auto">
						<div class="settings-btn">
							<router-link to="/settings"><span class="icon icon--settings"></span> Настройки</router-link>
						</div>
						<div class="settings-btn">
							<router-link to="/promocodes"><span class="icon icon--promocode"></span> Промокоды</router-link>
						</div>
					</div>
				</div>
			</div>
			<div class="filters d-flex flex-wrap filters--exams align-items-center">
				<div class="filters-item mb-2 mb-md-0">
					<v-select 
						:options="categories" 
						:reduce="category => category.driver_category_id" 
						label="name" 
						:searchable="false" 
						:filterable="false"
						placeholder="Категория"
						@open="onOpenCategories"
						@close="onCloseCategories"
						@input="setCategory"
						ref="categorySelect"
						>
						<template #list-footer>
							<li v-show="hasNextPageCategories" ref="loadCategories" class="loader select-loader">
								Загрузка...
							</li>
						</template>
					</v-select>
				</div>
				<div class="filters-item mb-2 mb-md-0">
					<v-select 
						:options="locations" 
						:reduce="location => location.location_id" 
						label="address" 
						:searchable="false" 
						:filterable="false"
						placeholder="Локация"
						@open="onOpenLocations"
						@close="onCloseLocations"
						@input="setLocation"
						ref="locationSelect"
						>
						<template #list-footer>
							<li v-show="hasNextPageLocations" ref="loadLocations" class="loader select-loader">
								Загрузка...
							</li>
						</template>
					</v-select>
				</div>
				<div class="filters-item">
					<v-select
						:options="statuses"
						:reduce="status => status.code"
						label="name"
						:searchable="false"
						placeholder="Статус"
						@input="setStatus"
						ref="statusSelect"
					></v-select>
				</div>
				<button type="button" class="reset-filters filters-item--100 align-self-center mt-3 mb-3 mt-xl-0 mb-md-0 mr-auto" @click="resetFilters"> Сбросить фильтры </button>
				<div class="settings-search settings-search--exams  d-none d-md-block">
					<input 
						v-model.trim="search"
            v-debounce:1000ms="searchByExams" 
						type="text" 
						class="form-control form-control--search" 
						placeholder="Поиск по экзаменам" 
					>
					<button></button>
				</div>
			</div>
			<div class="school-cabinet_wrapper">
				<div class="school-cabinet_wrapper-content" data-simplebar data-simplebar-auto-hide="false">
					<div class="school-cabinet_table school-cabinet_table--exams">
						<div class="school-cabinet_table-title school-cabinet_table-title--1">ID</div>
						<div class="school-cabinet_table-title school-cabinet_table-title--2">Экзамен</div>
						<div class="school-cabinet_table-title school-cabinet_table-title--3">Статус</div>
						<div class="school-cabinet_table-title school-cabinet_table-title--4"> Свободно / Занято / Всего </div>
					</div>
					<div class="school-cabinet_card school-cabinet_card--exams" v-for="exam in exams" :key="exam.id">
						<div class="school-cabinet_card-top d-flex align-items-center">
							<div class="school-cabinet_card-info school-cabinet_card-info--id">
								<div class="school-cabinet_card-info-item">
									<div class="school-cabinet_card-info-text">
										<span class="light">{{ exam.examination_id }}</span>
									</div>
								</div>
							</div>
							<div class="school-cabinet_card-info school-cabinet_card-info--exam">
								<div class="school-cabinet_card-info-item mr-0 pr-0 pr-md-1">
									<div class="school-cabinet_card-info-title">
										<strong>{{ exam.driver_category.name }} ({{formatDate(exam.date)}})</strong>
									</div>
									<div class="school-cabinet_card-info-text mb-2 mb-md-0">
										<span class="light">{{ exam.location.mreo }} {{ exam.location.address }}</span>
									</div>
								</div>
							</div>
							<div class="school-cabinet_card-info school-cabinet_card-info--status">
								<div class="school-cabinet_card-info-item mr-0 pr-0 pr-md-1">
									<div class="school-cabinet_card-info-title">
										<strong>{{ getExamStatus(exam.status) }}</strong>
									</div>
								</div>
							</div>
							<div class="school-cabinet_card-info school-cabinet_card-info--stat">
								<div class="school-cabinet_card-info-item mr-0 pr-0 pr-md-1">
									<div class="school-cabinet_card-info-title">
										<strong>{{ exam.slots.available }} / {{ exam.slots.assigned }} / {{ exam.slots.total }}</strong>
									</div>
								</div>
							</div>
							<router-link :to="`/clients/${exam.examination_id}`" class="school-cabinet_card-button school-cabinet_card-button--lessons school-cabinet_card-button--group d-flex justify-content-center align-items-center ml-auto">
								<div class="school-cabinet_card-button-icon"></div>
								<div class="school-cabinet_card-button-title">{{ exam.slots.assigned }}</div>
							</router-link>
							<div class="d-flex align-items-center mt-3 mt-md-0 mb-1 mb-md-0">
								<router-link :to="`/exam-edit/${exam.examination_id}`" class="school-cabinet_card-edit"></router-link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="custom-paginate">
			<pagination v-model="page" v-if="meta.total" :per-page="meta.per_page" :records="meta.total" @paginate="cahngePage" :options="options" />
		</div>
	</div>
</template>

<script>
import Pagination from 'vue-pagination-2';
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
export default {
	name: 'ProductList',
	data() {
		return {
			page: 1,
			code: false,
			observerCategories: null,
			observerLocations: null,
			options: {
				edgeNavigation: false,
				texts: {
					count: ''
				}
			},
			search: '',
		}
	},
	components: {
		Pagination,
	},
	destroyed() {
		this.clearLocations();
		this.clearCategories();
		this.clearStatuses();
	},
	mounted() {
		this.getExams(this.page);
		this.getCategories();
		this.getLocations();
		this.getStatuses();
		this.observerCategories = new IntersectionObserver(this.infiniteScrollCategories);
		this.observerLocations = new IntersectionObserver(this.infiniteScrollLocations);
	},
	computed: {
		...mapGetters('exams', {
			exams: 'list',
			meta: 'meta',
		}),
		...mapGetters('categories', {
			categories: 'list',
			categoriesMeta: 'meta',
		}),
		...mapGetters('locations', {
			locations: 'list',
			locationsMeta: 'meta',
		}),
		...mapGetters('statuses', {
			statuses: 'list',
		}),
		hasNextPageCategories() {
			return this.categories.length < parseInt(this.categoriesMeta.total)
		},
		hasNextPageLocations() {
			return this.locations.length < parseInt(this.locationsMeta.total)
		},
	},
	methods: {
		...mapActions('exams', {
			getExams: 'getList',
			setParam: 'setParam',
			clearExam: 'clearExam',
			resetExamsFilters: 'resetFilters',
			changePageExams: 'changePageExams',
			changeFiltersSearchExams: 'changeFiltersSearchExams',
		}),
		...mapActions('categories', {
			getCategories: 'getList',
			clearCategories: 'clearCategories',
		}),
		...mapActions('locations', {
			getLocations: 'getList',
			clearLocations: 'clear',
		}),
		...mapActions('statuses', {
			getStatuses: 'getList',
			clearStatuses: 'clear',
		}),
		formatDate(date) {
			return moment(date).format('DD.MM.YYYY');
		},
		setLocation(location) {
			let payload = {
				type: 'location_id',
				value: location,
			} 
			this.setParam(payload).then(() => {
				this.clearExam().then(() => {
					this.getExams();
				})
			})
		},
		setStatus(status) {
			let payload = {
				type: 'status',
				value: status,
			} 
			this.setParam(payload).then(() => {
				this.clearExam().then(() => {
					this.getExams();
				})
			})
		},
		setCategory(category) {
			let payload = {
				type: 'driver_category_id',
				value: category,
			} 
			this.setParam(payload).then(() => {
				this.clearExam().then(() => {
					this.getExams();
				})
			})
		},
		searchByExams(value) {
      const search = value.trim();

      this.changePageExams(1);
      this.page = 1;

      let query = Object.assign({}, this.$route.query);
      query.page = '1';

      if (!search) {
        delete query.search;
      } else {
        query.search = search;
      }

      this.$router.push({ query }).catch(() => {});

      this.changeFiltersSearchExams(search);

      this.getExams()
    },
		resetFilters() {
			this.$refs.locationSelect.clearSelection();
			this.$refs.categorySelect.clearSelection();
			this.$refs.statusSelect.clearSelection();
			this.search = '';
			this.resetExamsFilters().then(() => {
				this.clearExam().then(() => {
					this.getExams();
				})
			})
		},
		cahngePage() {
			this.getExams(this.page);
		},
		getExamStatus(code) {
			if (code == 'assignment') {
				return 'Распределение'
			}
			if (code == 'registration_opened') {
				return 'Открыта регистрация'
			}
			if (code == 'examination') {
				return 'Идет экзамен'
			}
			if (code == 'completed') {
				return 'Завершен'
			}
		},
		async onOpenCategories() {
			if (this.hasNextPageCategories) {
				await this.$nextTick()
				this.observerCategories.observe(this.$refs.loadCategories)
			}
		},
		onCloseCategories() {
			this.observerCategories.disconnect()
		},
		async infiniteScrollCategories([{ isIntersecting, target }]) {
			if (isIntersecting) {
				const ul = target.offsetParent
				const scrollTop = target.offsetParent.scrollTop
				await this.getCategories();
				ul.scrollTop = scrollTop
			}
		},
		async onOpenLocations() {
			if (this.hasNextPageLocations) {
				await this.$nextTick()
				this.observerLocations.observe(this.$refs.loadLocations)
			}
		},
		onCloseLocations() {
			this.observerLocations.disconnect()
		},
		async infiniteScrollLocations([{ isIntersecting, target }]) {
			if (isIntersecting) {
				const ul = target.offsetParent
				const scrollTop = target.offsetParent.scrollTop
				await this.getLocations();
				ul.scrollTop = scrollTop
			}
		},
	},
}
</script>

<style>
	.custom-paginate {
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
	}
	.reset-filters {
		padding: 0;
		font-size: inherit;
		line-height: 14px;
		border: none;
		color: #868686;
		background-color: transparent;
		text-decoration: underline;
		cursor: pointer;
	}
	.settings-btns {
		min-width: 1px !important;
	}
	.vs--single .vs__selected {
		line-height: 2;
	}
	.filters .vs__selected {
		white-space:  nowrap;
		overflow: hidden;
		display: block;
		max-width: 130px;
		-ms-text-overflow: ellipsis;
		text-overflow: ellipsis;
	}
	.settings-btn span.icon--promocode {
		background-image: url(~@/assets/img/percent-icon.svg);
	}
</style>