<template>
	<div class="settings">
		<div class="settings-top">
			<div class="settings-head settings-head--exams d-flex align-items-center flex-wrap">
					<router-link to="/" class="account-back d-flex align-items-center mr-4">
						<i class="fas fa-chevron-left"></i>
						<div class="account-back-text">Назад</div>
					</router-link>
					<div class="settings-title">Промокоды</div>
					<router-link to="/promocode-add" class="settings-head-icon"></router-link>
			</div>
		</div>
		<!-- <div class="filters d-flex flex-wrap filters--exams align-items-center justify-content-between">
			<div class="filters-item mb-2 mb-md-0">
				<select class="select2">
					<option value="" selected>Только активные</option>
					<option value="">Не использованные</option>
					<option value="">Использованные</option>
				</select>
			</div>
			<div class="settings-search settings-search--promocodes mt-2 my-md-0 ml-auto">
				<input type="text" class="form-control form-control--search" placeholder="Поиск по промокоду">
				<button></button>
			</div>
		</div> -->
		<div class="school-cabinet_wrapper">
			<div class="school-cabinet_wrapper-content" data-simplebar data-simplebar-auto-hide="false" v-if="promocodes && promocodes.length > 0">
				<div class="school-cabinet_table school-cabinet_table--promocodes">
					<div class="school-cabinet_table-title school-cabinet_table-title--1">Промокод</div>
					<div class="school-cabinet_table-title school-cabinet_table-title--2">Активен</div>
					<div class="school-cabinet_table-title school-cabinet_table-title--3">Статус</div>
					<div class="school-cabinet_table-title school-cabinet_table-title--4">Использован</div>
				</div>
				<div class="school-cabinet_card school-cabinet_card--promocodes" v-for="item in promocodes" :key="item.id">
					<div class="school-cabinet_card-top d-flex align-items-center">
							<div class="school-cabinet_card-info-item school-cabinet_card-info-item--promo">                                   
								<div class="school-cabinet_card-info-text">
										<strong>{{ item.code }}</strong>
								</div>
							</div>
							<div class="school-cabinet_card-info-item school-cabinet_card-info-item--date">                                   
								<div class="school-cabinet_card-info-text">
										<span class="normal">{{formatDate(item.active_from)}} - {{formatDate(item.active_to)}}</span>
								</div>
							</div>
							<div class="school-cabinet_card-info-item school-cabinet_card-info-item--status">                                   
								<div class="school-cabinet_card-info-text">
										<span class="normal">{{ getPromocodeStatus(item.status) }}</span>
								</div>
							</div>
							<div class="school-cabinet_card-info-item school-cabinet_card-info-item--use">                                   
								<div class="school-cabinet_card-info-text">
										<span class="normal">{{ getUsedStatus(item.is_used) }}</span>
								</div>
							</div>
							<div class="d-flex align-items-center ml-auto">
								<router-link :to="`/promocode-edit/${item.id}`" class="school-cabinet_card-edit ml-4"></router-link>
							</div>
					</div>
				</div>
			</div>
			<p v-else>Промокодов пока нет</p>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';

export default {
	name: "Promocodes",
	data() {
		return {
			code: false,
		}
	},
	mounted() {
		this.getPromocodes();
	},
	destroyed() {
	},
	computed: {
		...mapGetters('promocodes', {
			promocodes: 'list',
		}),
	},
	methods: {
		...mapActions('promocodes', {
			getPromocodes: 'getList',
		}),
		formatDate(date) {
			return moment(date).format('DD.MM.YYYY');
		},
		getPromocodeStatus(code) {
			if (code == 'active') {
				return 'Активен'
			}
			if (code == 'inactive') {
				return 'не активен'
			}
		},
		getUsedStatus(code) {
			if (code == false) {
				return 'Нет'
			}
			if (code == true) {
				return 'Да'
			}
		},
	}
}
</script>