<template>
	<div class="page">
		<router-link :to="`/clients/${this.$route.query.exam}`" class="account-back d-flex align-items-center mb-3">
			<i class="fas fa-chevron-left"></i>
			<div class="account-back-text">Назад</div>
		</router-link>
		<div class="school-cabinet">
			<div class="school-cabinet_white-box">
				<div class="school-cabinet_info">
					<div class="school-cabinet_info-title text-left mb-4 pb-1">
						<strong>Редактировать клиента</strong>
					</div>
					<div class="school-cabinet_info-form school-cabinet_info-form--full">
						<div class="school-cabinet_info-row">
							<div class="d-flex justify-content-between">
								<div class="school-cabinet_info-field">
									<v-select 
										:options="statusesClient" 
										:reduce="status => status.code" 
										label="name" 
										:searchable="false" 
										placeholder="Статус"
										v-model="client_exam.status"
										>
									</v-select>
								</div>
								<div class="school-cabinet_info-field">
									<v-select 
										:options="instructors" 
										:filterable="false"
										:reduce="instructor => instructor.instructor_id" 
										label="full_name" 
										:searchable="true" 
										placeholder="Инструктор"
										@open="onOpenInstructors"
										@close="onCloseInstructors"
										@search="searchInstructor"
										@input="selectInstructor"
										>
										<template #list-footer>
											<li v-show="hasNextPageInstructors" ref="loadInstructors" class="loader select-loader">
												Загрузка...
											</li>
										</template>
									</v-select>
								</div>
							</div>
						</div>
						<div class="school-cabinet_info-button text-center mt-4">
							<button class="btn btn-long btn-blue" @click="update" v-if="!isLoading">Сохранить</button>
							<button class="btn btn-long btn-blue" disabled v-else>Сохраняем...</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapActions, mapGetters } from 'vuex';
	import errorHandlerForResponse from '@/helpers/errorHandlerForResponse.js';
	import _ from 'lodash';

	export default {
		name: "ClientsEdit",
		data() {
			return {
				observerInstructors: null,
				isLoading: false,
				client_exam: {
					client_id: '',
					instructor_id: '',
					examination_id: '',
					status: '',
				},
			}
		},
		mounted() {
			let id = this.$route.params.id;
			this.getClient(id).then(() => {
				this.client_exam.status = this.client.status;
				this.client_exam.instructor_id = this.client.instructor.instructor_id;
			});
			this.getInstructors(this.$route.query.exam);
			this.observerInstructors = new IntersectionObserver(this.infiniteScrollInstructors);
		},
		destroyed() {
			this.clearInstructors();
		},
		computed: {
			...mapGetters('statusesClient', {
				statusesClient: 'statuses',
			}),
			...mapGetters('instructors', {
				instructors: 'instructors',
				instructorsMeta: 'meta',
			}),
			...mapGetters('clients', {
				client: 'item',
			}),
			hasNextPageInstructors() {
				return this.instructors.length < parseInt(this.instructorsMeta.total)
			},
		},
		methods: {
			...mapActions('instructors', {
				getInstructors: 'getExamInstructors',
				clearInstructors: 'clear',
				changeQueryInstructors: 'changeQueryInstructors',
			}),
			...mapActions('clients', {
				getClient: 'getClient',
				editClient: 'update',
			}),
			selectInstructor(instructor) {
				this.client_exam.instructor_id = instructor
			},
			async onOpenInstructors() {
				if (this.hasNextPageInstructors) {
					await this.$nextTick()
					this.observerInstructors.observe(this.$refs.loadInstructors)
				}
			},
			onCloseInstructors() {
				this.observerInstructors.disconnect()
			},
			async infiniteScrollInstructors([{ isIntersecting, target }]) {
				if (isIntersecting) {
					const ul = target.offsetParent
					const scrollTop = target.offsetParent.scrollTop
					await this.getInstructors(this.$route.query.exam);
					ul.scrollTop = scrollTop
				}
			},
			searchInstructor(search, loading) {
				this.clearInstructors();
				this.changeQueryInstructors(search);
				loading(true);
				this.searchInstructorsAction(search, loading, this);
			},
			searchInstructorsAction: _.debounce((search, loading, vm) => {
				vm.getInstructors(vm.$route.query.exam).then(() => {
					loading(false);
				})
			}, 1000),
			update() {
				this.isLoading = true;
				let id = this.client.client_exam_id;
				let payload = {
					"id": id,
					"type": "PATCH",
					"client_exam": this.client_exam
				}
				this.editClient(payload).then(() => {
					this.isLoading = false;
					this.$router.push(`/clients/${this.$route.query.exam}`);
				}).catch((e) => {
					this.isLoading = false;
					this.errorHandlerForResponse(e);
				})
			},
			errorHandlerForResponse,
		},
	}
</script>

<style>
	.vs__dropdown-toggle {
		border:  1px solid #E1E1E1;
		-webkit-border-radius: 4px;
		-moz-border-radius: 4px;
		border-radius: 4px;
		outline: none;
		height: 44px;
	}

	.vs__search {
		font-size: 15px !important;
		color: #555 !important;
		font-family: 'Proxima Nova';
		padding: 0 !important;
	}

	.vs__dropdown-menu {
		padding: 0 !important;
	}

	.vs__dropdown-option {
		font-size: 14px !important;
		color: #000 !important;
		padding: 14px !important;
	}

	.vs__dropdown-option--highlight {
		background: #D2E1F7 !important;
		color: #555 !important;
	}

	.vs__selected-options {
		padding: 0 14px !important;
	}

	.vs__clear {
		display: none !important;
	}

	.vs__open-indicator {
		display: none !important;
	}

	.vs__actions:after {
		content: '';
		border-color: #999999 transparent transparent transparent;
		border-style: solid;
		border-width: 5px 4px 0 4px;
		height: 0;
		width: 0;
		position: absolute;
		top: 0;
		bottom: 0;
		margin: auto;
		right: 19px;
	}

	.vs--open .vs__actions:after {
		transform: rotate(180deg) !important;
	}
</style>