import axios from 'axios'
import settings from '@/settings.js'
let token = localStorage.getItem('token');

export default {
  namespaced: true,
  state: {
    list: [],
    instructors: [],
    meta: {},
    instructors_meta: {},
    instructors_list: [],
    page: 1,
    filters: {
      page: 1,
      query: '',
      examination_id: '',
    },
    filtersList: {
      page: 1,
      query: '',
    }
  },

  getters: {
    list: state => {
      return state.list;
    },
    meta: state => {
      return state.meta;
    },
    instructors_meta: state => {
      return state.instructors_meta;
    },
    instructors: state => {
      return state.instructors;
    },
    instructors_list: state => {
      return state.instructors_list;
    },
  },

  actions: {
    async getList({commit, state}, page) {
      if (page) {
        let list = await axios.get(`${settings.APP_SERVICE_URL}rest/instructor?page=${page}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        commit('SET_LIST', list.data)
      } else {
        let list = await axios.get(`${settings.APP_SERVICE_URL}rest/instructor?page=${state.page}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        commit('PUSH_LIST', list.data)
      }
    },
    async getExamInstructors({commit, state}, id) {
      commit('SET_FILTER_EXAMINATION_ID', id)
      const params = Object.fromEntries(Object.entries(state.filters).filter(([key, value]) => key && value));
      let instructors = await axios.get(`${settings.APP_SERVICE_URL}rest/instructor`, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: params,
      });
      commit('SET_INSTRUCTORS', instructors.data)
    },
    async getListInstructors({commit, state}) {
      const params = Object.fromEntries(Object.entries(state.filtersList).filter(([key, value]) => key && value));
      let instructors = await axios.get(`${settings.APP_SERVICE_URL}rest/instructor`, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: params,
      });
      commit('SET_INSTRUCTORS_LIST', instructors.data)
    },
    changeQueryInstructors({ commit }, query) {
      commit('SET_QUERY_INSTRUCTORS', query);
    },
    clear({ commit }) {
      commit('CLEAR');
    }
  },

  mutations: {
    PUSH_LIST(state, list) {
      state.page++;
      state.list.push(...list.data);
      state.meta  = list.meta;
    },
    SET_LIST(state, list) {
      state.list = list.data;
      state.meta  = list.meta;
    },
    CLEAR(state) {
      state.instructors = [];
      state.instructors_list = [];
      state.list = [];
      state.meta = {};
      state.instructors_meta = {};
      state.page = 1;
      state.filtersList.page = 1;
    },
    SET_INSTRUCTORS(state, instructors) {
      state.instructors  = instructors.data;
      state.instructors_meta  = instructors.meta;
    },
    SET_INSTRUCTORS_LIST(state, instructors) {
      state.instructors_list.push(...instructors.data);
      state.instructors_meta  = instructors.meta;
      state.filtersList.page++;
    },
    SET_QUERY_INSTRUCTORS(state, query) {
      state.filters.query = query;
      state.filtersList.query = query;
    },
    SET_FILTER_EXAMINATION_ID(state, id) {
      state.filters.examination_id = id;
    },
  },
};
