<template>
	<div class="page">
		<router-link to="/" class="account-back d-flex align-items-center mb-3">
			<i class="fas fa-chevron-left"></i>
			<div class="account-back-text">Назад</div>
		</router-link>
		<div class="school-cabinet">
			<div class="school-cabinet_white-box">
				<div class="school-cabinet_info">
					<div class="school-cabinet_info-title text-left mb-4 pb-1">
						<strong>Добавить экзамен</strong>
					</div>
					<div class="school-cabinet_info-form school-cabinet_info-form--full">
						<div class="school-cabinet_info-row">
							<div class="d-flex justify-content-between">
								<div class="school-cabinet_info-field">
									<v-select 
										:options="categories" 
										:reduce="category => category.driver_category_id" 
										label="name" 
										v-model="examination.driver_category_id" 
										:searchable="false" 
										:filterable="false"
										placeholder="Водительская категория"
										@open="onOpenCategories"
										@close="onCloseCategories"
										>
										<template #list-footer>
											<li v-show="hasNextPageCategories" ref="loadCategories" class="loader select-loader">
												Загрузка...
											</li>
										</template>
									</v-select>
								</div>
								<div class="school-cabinet_info-field">
									<v-select 
										:options="locations" 
										:reduce="location => location.location_id" 
										label="address" 
										v-model="examination.location_id" 
										:searchable="false" 
										:filterable="false"
										placeholder="Локация"
										@open="onOpenLocations"
										@close="onCloseLocations"
										>
										<template #list-footer>
											<li v-show="hasNextPageLocations" ref="loadLocations" class="loader select-loader">
												Загрузка...
											</li>
										</template>
									</v-select>
								</div>
							</div>
						</div>
						<div class="school-cabinet_info-row">
							<div class="d-flex justify-content-between">
								<div class="school-cabinet_info-field">
									<v-select :options="statuses" :reduce="status => status.code" label="name" v-model="examination.status" :searchable="false" placeholder="Статус"></v-select>
								</div>
								<div class="school-cabinet_info-field">
									<input type="text" class="form-control form-control--bordered" placeholder="Кол-во слотов" v-model="examination.slots_total">
								</div>
							</div>
						</div>
						<div class="school-cabinet_info-row">
							<div class="d-flex justify-content-between">
								<div class="school-cabinet_info-field">
									<v-select
										:options="instructors"
										:reduce="instructor => instructor"
										label="full_name"
										:searchable="true"
										:filterable="false"
										placeholder="Инструкторы"
										@input="addInstructor"
										v-model="instructor"
										@open="onOpenInstructors"
										@close="onCloseInstructors"
										@search="searchInstructor"
										>
										<template #list-footer>
											<li v-show="hasNextPageInstructors" ref="loadInstructors" class="loader select-loader">
												Загрузка...
											</li>
										</template>
									</v-select>
								</div>
								<div class="school-cabinet_info-field">
									<input type="text" class="form-control form-control--calendar-icon form-control--bordered" placeholder="Дата экзамена" id="calendar-modal">
								</div>
							</div>
						</div>
						<div class="school-cabinet_info-instructor added-instructor d-flex">
							<div class="added-instructor__item active" v-for="instructor in selectedInstructorsChips" :key="instructor.instructor_id">
								{{instructor.full_name}}
								<div class="added-instructor__item-icon" @click="closeChip(instructor.instructor_id)"></div>
							</div>
						</div>
						<div class="school-cabinet_info-button text-center mt-4">
							<button class="btn btn-long btn-blue" @click="save" v-if="!isLoading">Сохранить</button>
							<button class="btn btn-long btn-blue" disabled v-else>Сохраняем...</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapActions, mapGetters } from 'vuex';
	import errorHandlerForResponse from '@/helpers/errorHandlerForResponse.js';
	import _ from 'lodash';

	import AirDatepicker from 'air-datepicker';
	import 'air-datepicker/air-datepicker.css';
	export default {
		name: "ExamsAdd",
		data() {
			return {
				observerInstructors: null,
				observerCategories: null,
				observerLocations: null,
				instructor: null,
				isLoading: false,
				examination: {
					driver_category_id: '',
					location_id: '',
					slots_total: '',
					status: '',
					date: '',
					instructor_ids: [],
				},
				selectedInstructorsChips: [],
			}
		},
		mounted() {
			this.getCategories();
			this.getLocations();
			this.getStatuses();
			this.getInstructors();
			let that = this;
			new AirDatepicker('#calendar-modal', {
				dateFormat(date) {
						return date.toLocaleString('en-CA', {
								year: 'numeric',
								day: '2-digit',
								month: 'numeric'
						});
				},
				onSelect(date) {
					that.examination.date = date.formattedDate
					console.log(date);
				}
			});
			this.observerInstructors = new IntersectionObserver(this.infiniteScrollInstructors);
			this.observerCategories = new IntersectionObserver(this.infiniteScrollCategories);
			this.observerLocations = new IntersectionObserver(this.infiniteScrollLocations);
		},
		destroyed() {
			this.clearInstructors();
			this.clearLocations();
			this.clearCategories();
			this.clearStatuses();
		},
		computed: {
			...mapGetters('categories', {
				categories: 'list',
				categoriesMeta: 'meta',
			}),
			...mapGetters('locations', {
				locations: 'list',
				locationsMeta: 'meta',
			}),
			...mapGetters('statuses', {
				statuses: 'list',
			}),
			...mapGetters('instructors', {
				instructors: 'instructors_list',
				instructorsMeta: 'instructors_meta',
			}),
			hasNextPageInstructors() {
				return this.instructors.length < parseInt(this.instructorsMeta.total)
			},
			hasNextPageCategories() {
				return this.categories.length < parseInt(this.categoriesMeta.total)
			},
			hasNextPageLocations() {
				return this.locations.length < parseInt(this.locationsMeta.total)
			},
			selectedInstructors() {
				return this.instructors.filter((item) => {
					return this.examination.instructor_ids.indexOf(item.instructor_id) !== -1;
				});
			},
		},
		methods: {
			...mapActions('categories', {
				getCategories: 'getList',
				clearCategories: 'clearCategories',
			}),
			...mapActions('locations', {
				getLocations: 'getList',
				clearLocations: 'clear',
			}),
			...mapActions('statuses', {
				getStatuses: 'getList',
				clearStatuses: 'clear',
			}),
			...mapActions('instructors', {
				getInstructors: 'getListInstructors',
				clearInstructors: 'clear',
				changeQueryInstructors: 'changeQueryInstructors',
			}),
			...mapActions('exams', {
				setExam: 'save',
			}),
			addInstructor(value) {
				let index = this.examination.instructor_ids.indexOf(value.instructor_id);
				if (index == -1) {
					this.examination.instructor_ids.push(value.instructor_id);
					this.instructor = null;
					this.selectedInstructorsChips.push(value);
				}
			},
			async onOpenInstructors() {
				if (this.hasNextPageInstructors) {
					await this.$nextTick()
					this.observerInstructors.observe(this.$refs.loadInstructors)
				}
			},
			onCloseInstructors() {
				this.observerInstructors.disconnect()
			},
			async infiniteScrollInstructors([{ isIntersecting, target }]) {
				if (isIntersecting) {
					const ul = target.offsetParent
					const scrollTop = target.offsetParent.scrollTop
					await this.getInstructors();
					ul.scrollTop = scrollTop
				}
			},
			searchInstructor(search, loading) {
				this.clearInstructors();
				this.changeQueryInstructors(search);
				loading(true);
				this.searchInstructorsAction(search, loading, this);
			},
			searchInstructorsAction: _.debounce((search, loading, vm) => {
				vm.getInstructors().then(() => {
					loading(false);
				})
			}, 1000),
			async onOpenCategories() {
				if (this.hasNextPageCategories) {
					await this.$nextTick()
					this.observerCategories.observe(this.$refs.loadCategories)
				}
			},
			onCloseCategories() {
				this.observerCategories.disconnect()
			},
			async infiniteScrollCategories([{ isIntersecting, target }]) {
				if (isIntersecting) {
					const ul = target.offsetParent
					const scrollTop = target.offsetParent.scrollTop
					await this.getCategories();
					ul.scrollTop = scrollTop
				}
			},
			async onOpenLocations() {
				if (this.hasNextPageLocations) {
					await this.$nextTick()
					this.observerLocations.observe(this.$refs.loadLocations)
				}
			},
			onCloseLocations() {
				this.observerLocations.disconnect()
			},
			async infiniteScrollLocations([{ isIntersecting, target }]) {
				if (isIntersecting) {
					const ul = target.offsetParent
					const scrollTop = target.offsetParent.scrollTop
					await this.getLocations();
					ul.scrollTop = scrollTop
				}
			},
			closeChip(id) {
				let index = this.examination.instructor_ids.indexOf(id);
				if (index !== -1) {
					this.examination.instructor_ids.splice(index, 1)
					const indexChips = this.selectedInstructorsChips.findIndex(i => i.instructor_id === id);
					if (indexChips !== -1) {
						this.selectedInstructorsChips.splice(indexChips, 1)
					}
				}
			},
			save() {
				this.isLoading = true;
				let payload = {
					"examination": this.examination
				}
				this.setExam(payload).then(() => {
					this.isLoading = false;
					this.$router.push('/');
				}).catch((e) => {
					this.isLoading = false;
					this.errorHandlerForResponse(e);
				})
			},
			errorHandlerForResponse,
		},
	}
</script>

<style>
	.vs__dropdown-toggle {
		border:  1px solid #E1E1E1;
		-webkit-border-radius: 4px;
		-moz-border-radius: 4px;
		border-radius: 4px;
		outline: none;
		height: 44px;
	}

	.vs__search {
		font-size: 15px !important;
		color: #555 !important;
		font-family: 'Proxima Nova';
		padding: 0 !important;
	}

	.vs__dropdown-menu {
		padding: 0 !important;
	}

	.vs__dropdown-option {
		font-size: 14px !important;
		color: #000 !important;
		padding: 14px !important;
	}

	.vs__dropdown-option--highlight {
		background: #D2E1F7 !important;
		color: #555 !important;
	}

	.vs__selected-options {
		padding: 0 14px !important;
	}

	.vs__clear {
		display: none !important;
	}

	.vs__open-indicator {
		display: none !important;
	}

	.vs__actions:after {
		content: '';
		border-color: #999999 transparent transparent transparent;
		border-style: solid;
		border-width: 5px 4px 0 4px;
		height: 0;
		width: 0;
		position: absolute;
		top: 0;
		bottom: 0;
		margin: auto;
		right: 19px;
	}

	.vs--open .vs__actions:after {
		transform: rotate(180deg) !important;
	}
</style>