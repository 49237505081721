import axios from 'axios'
import settings from '@/settings.js'
let token = localStorage.getItem('token');

export default {
  namespaced: true,
  state: {
    list: [],
    item: null,
  },

  getters: {
    list: state => {
      return state.list;
    },
    item: state => {
      return state.item;
    },
  },

  actions: {
    async getList({commit} ) {
      let list = await axios.get(`${settings.APP_SERVICE_URL}rest/promocode`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      commit('SET_LIST', list.data)
    },
    async getItem({commit}, id) {
      let item = await axios.get(`${settings.APP_SERVICE_URL}rest/promocode/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      commit('SET_ITEM', item.data)
    },
    /* eslint-disable no-empty-pattern */
    async save({ }, payload) {
      await axios.post(`${settings.APP_SERVICE_URL}rest/promocode/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
    },
    async update({ }, payload) {
      await axios.put(`${settings.APP_SERVICE_URL}rest/promocode/${payload.id}`, payload.promocode, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
    },
  },

  mutations: {
    SET_LIST(state, list) {
      state.list = list.data;
      state.meta  = list.meta;
    },
    SET_ITEM(state, item) {
      state.item  = item.promocodes;
    },
    // CLEAR(state) {
    //   state.list = [];
    //   state.meta = {};
    //   state.item = null;
    //   state.page = 1;
    // },
  },
};
