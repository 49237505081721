import axios from 'axios'
import settings from '@/settings.js'
let token = localStorage.getItem('token');

export default {
  namespaced: true,
  state: {
    list: [],
    meta: {},
    page: 1,
    statuses: [
      {
        code: 'assignment',
        name: 'Зачислен'
      },
      {
        code: 'examination',
        name: 'Экзамен идет'
      },
      {
        code: 'confirmed',
        name: 'Подтвержен'
      },
      {
        code: 'success',
        name: 'Успешно сдан'
      },
      {
        code: 'not_confirmed',
        name: 'Не подтвержден'
      },
      {
        code: 'fail',
        name: 'Провален'
      },
      {
        code: 'canceled',
        name: 'Отменен'
      },
      {
        code: 'abandoned',
        name: 'Забираю документы'
      },
    ]
  },

  getters: {
    list: state => {
      return state.list;
    },
    statuses: state => {
      return state.statuses;
    },
    meta: state => {
      return state.meta;
    },
  },

  actions: {
    async getList({commit, state}, page) {
      if (page) {
        let list = await axios.get(`${settings.APP_SERVICE_URL}rest/client-exam/status?page=${page}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        commit('SET_LIST', list.data)
      } else {
        let list = await axios.get(`${settings.APP_SERVICE_URL}rest/client-exam/status?page=${state.page}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        commit('PUSH_LIST', list.data)
      }
    },
    clear({ commit }) {
      commit('CLEAR');
    }
  },

  mutations: {
    PUSH_LIST(state, list) {
      state.page++;
      state.list.push(...list.data);
      state.meta  = list.meta;
    },
    SET_LIST(state, list) {
      state.list  = list.data;
      state.meta  = list.meta;
    },
    CLEAR(state) {
      state.list = [];
      state.meta = {};
      state.page = 1;
    },
  },
};
