<template>
	<div class="page">
		<div class="settings">
			<div class="settings-top">
				<div class="settings-head settings-head--exams d-flex align-items-center flex-wrap">
					<router-link to="/categories" class="account-back d-flex align-items-center mr-4">
						<i class="fas fa-chevron-left"></i>
						<div class="account-back-text">Назад</div>
					</router-link>
					<div class="settings-title">Водительские категории</div>
				</div>
			</div>
			<div class="settings-title settings-title--small">Редактировать категорию</div>
		</div>
		<div class="school-cabinet">
			<div class="school-cabinet_white-box school-cabinet_white-box--bigger mb-4">
				<div class="school-cabinet_info-form school-cabinet_info-form--full">
					<div class="d-flex justify-content-between flex-wrap">
						<div class="school-cabinet_info-field school-cabinet_info-field--small mb-md-0">
							<input type="text" class="form-control form-control--bordered" placeholder="Категория" v-model="driver_category.category">
						</div>
						<div class="school-cabinet_info-field school-cabinet_info-field--small mb-md-0">
							<input type="text" class="form-control form-control--bordered" placeholder="Название" v-model="driver_category.name">
						</div>
						<div class="school-cabinet_info-field school-cabinet_info-field--small mb-0">
							<button class="btn btn-blue btn-full" @click="update" v-if="!isLoading">Сохранить</button>
							<button class="btn btn-blue btn-full" disabled v-else>Сохраняем...</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import errorHandlerForResponse from '@/helpers/errorHandlerForResponse.js';

export default {
	name: "Categories",
	data() {
		return {
			isLoading: false,
			driver_category: {
				category: '',
				name: '',
			}
		}
	},
	mounted() {
		let id = this.$route.params.id;
		this.getCategory(id).then(() => {
			this.driver_category.category = this.category.category;
			this.driver_category.name = this.category.name;
		});
	},
	destroyed() {
		this.clearCategories();
	},
	computed: {
		...mapGetters('categories', {
			category: 'item',
		}),
	},
	methods: {
		...mapActions('categories', {
			getCategory: 'getCategory',
			clearCategories: 'clearCategories',
			editCategory: 'update',
		}),
		update() {
			this.isLoading = true;
			let id = this.category.driver_category_id;
			let payload = {
				"id": id,
				"type": "PATCH",
				"driver_category": this.driver_category
			}
			this.editCategory(payload).then(() => {
				this.isLoading = false;
				this.$router.push('/categories');
			}).catch((e) => {
				this.isLoading = false;
				this.errorHandlerForResponse(e);
			})
		},
		errorHandlerForResponse,
	}
}
</script>