import axios from 'axios'
import settings from '@/settings.js'
let token = localStorage.getItem('token');

export default {
  namespaced: true,
  state: {
    list: [],
    clients: [],
    meta: {},
    item: null,
    filtersClients: {
      page: 1,
      search: '',
      query: '',
    },
    filters: {
      page: 1,
      page_size: 10,
      location_id: null,
      status: '',
      query: '',
      driver_category_id: null,
    },
  },

  getters: {
    list: state => {
      return state.list;
    },
    meta: state => {
      return state.meta;
    },
    item: state => {
      return state.item;
    },
    clients: state => {
      return state.clients;
    },
  },

  actions: {
    async getList({commit, state}, page) {
      if (page) {
        commit('CHANGE_PAGE', page)
      }
      const params = Object.fromEntries(Object.entries(state.filters).filter(([key, value]) => key && value));
      let list = await axios.get(`${settings.APP_SERVICE_URL}rest/examination`, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: params
      });
      commit('SET_LIST', list.data)
    },
    async getExam({commit}, id) {
      let item = await axios.get(`${settings.APP_SERVICE_URL}rest/examination/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      commit('SET_ITEM', item.data)
    },
    async updateClientStatus({commit}, client) {
      commit('UPDATE_CLIENT_STATUS', client)
    },
    async getExamClients({commit, state}, id) {
      const params = Object.fromEntries(Object.entries(state.filtersClients).filter(([key, value]) => key && value));
      let clients = await axios.get(`${settings.APP_SERVICE_URL}rest/examination/${id}/client-exam`, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: params
      });
      commit('SET_CLIENTS', clients.data)
    },
    /* eslint-disable no-empty-pattern */
    async save({ }, payload) {
      await axios.post(`${settings.APP_SERVICE_URL}rest/examination/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
    },
    async update({ }, payload) {
      await axios.patch(`${settings.APP_SERVICE_URL}rest/examination/${payload.id}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
    },
    async exportData({ }, id) {
      let exportData = await axios.get(`${settings.APP_SERVICE_URL}rest/examination/${id}/export`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      
      var hiddenElement = document.createElement('a');
      hiddenElement.href = `data:text/csv;charset=utf-8, ${encodeURI(exportData.data)}`;
      hiddenElement.target = '_blank';
      hiddenElement.download = 'transactions_export.csv';
      hiddenElement.click();
    },
    clearExam({ commit }) {
      commit('CLEAR_EXAM');
    },
    clearExamClients({ commit }) {
      commit('CLEAR_EXAM_CLIENTS');
    },
    setParam({ commit }, payload) {
      commit('SET_PARAM', payload);
    },
    setParamClients({ commit }, payload) {
      commit('SET_PARAM_CLIENTS', payload);
    },
    resetFilters({ commit }) {
      commit('RESET_FILTERS');
    },
    resetFiltersClients({ commit }) {
      commit('RESET_FILTERS_CLIENTS');
    },
    changePageExams({ commit }, page) {
      commit('CHANGE_PAGE_EXAMS', page);
    },
    changePageExamClients({ commit }, page) {
      commit('CHANGE_PAGE_EXAM_CLIENTS', page);
    },
    changeFiltersSearchExams({ commit }, search) {
      commit('CHANGE_FILTERS_SEARCH_EXAMS', search);
    },
    changeFiltersSearchExamClients({ commit }, search) {
      commit('CHANGE_FILTERS_SEARCH_EXAM_CLIENTS', search);
    },
  },

  mutations: {
    UPDATE_CLIENT_STATUS(state, client) {
      state.clients.find( item => item.client_exam_id == client.client_exam_id).additional_registration_allowed = !client.additional_registration_allowed
    },
    CHANGE_PAGE_EXAMS(state, page) {
      state.filters.page = page;
    },
    CHANGE_PAGE_EXAMS_CLIENTS(state, page) {
      state.filtersClients.page = page;
    },
    CHANGE_FILTERS_SEARCH_EXAMS(state, search) {
      state.filters.query = search;
    },
    CHANGE_FILTERS_SEARCH_EXAM_CLIENTS(state, search) {
      state.filtersClients.query = search;
    },
    CHANGE_PAGE(state, page) {
      state.filters.page = page;
    },
    SET_LIST(state, list) {
      state.list  = list.data;
      state.meta  = list.meta;
    },
    SET_CLIENTS(state, clients) {
      state.clients  = clients.data;
    },
    SET_ITEM(state, item) {
      state.item  = item.examination;
    },
    SET_PARAM(state, payload) {
      state.filters[payload.type] = payload.value;
    },
    SET_PARAM_CLIENTS(state, payload) {
      state.filtersClients[payload.type] = payload.value;
    },
    SET_EXAM_BY_DATA(state, client) {
      let index = state.clients.find(e => e.client_exam_id == client.client_exam.client_exam_id);
      if (index > -1) {
        state.clients[index] = client.client_exam
      }
    },
    CLEAR_EXAM(state) {
      state.filters.page = 1;
      state.item = null;
      state.list = [];
      state.clients = [];
      state.meta = {};
    },
    CLEAR_EXAM_CLIENTS(state) {
      state.filtersClients.page = 1;
      state.clients = [];
      state.meta = {};
    },
    RESET_FILTERS(state) {
      state.filters = {
        page: 1,
        page_size: 10,
        location_id: null,
        status: '',
        search: '',
        driver_category_id: null,
      }
    },
    RESET_FILTERS_CLIENTS(state) {
      state.filtersClients = {
        page: 1,
        search: '',
      }
    },
  },
};
